.categories-container {
    padding: 16px;
}

.category {
    margin-bottom: 32px;
}

.category h2 {
    margin-bottom: 16px;
    font-size: 24px;
    /* color: #333; */
}

.posts-scroll-wrapper {
    position: relative;
}
.indexPage{
    padding: 10px;
    max-width: 100%;
    margin: 0 auto;
  }
  @media screen and (min-width: 1080px) {
    .indexPage {
      max-width: 100%;
    }
  }
.posts-scroll-container {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    padding: 10px 0;
}

.posts-scroll-container::-webkit-scrollbar {
    display: none; /* WebKit */
}

.posts-scroll-container > * {
    flex: 0 0 auto;
    width: 200px;
    margin-right: 16px;
}

.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 1;
}

.scroll-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.scroll-button.left {
    left: 10px;
}

.scroll-button.right {
    right: 10px;
}

@media (max-width: 600px) {
    .category h2 {
        font-size: 20px;
    }

    .scroll-button {
        width: 30px;
        height: 30px;
        font-size: 20px;
    }
}
.posts-scroll-container-continue {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 10px 0;
    gap: 15px;
  }
.genre-title {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    /* color: #333; */
    text-align: center;
    margin-bottom: 1.5rem;
    position: relative;
    padding-bottom: 0.5rem;
    overflow: hidden;
  }
  
  .genre-title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50px;
    height: 3px;
    background: linear-gradient(90deg, #037780, #031d91);
    transform: translateX(-50%);
    transition: width 0.3s ease;
  }
  
  .genre-title:hover::after {
    width: 100px;
  }
  
  @media (max-width: 768px) {
    .genre-title {
      font-size: 2rem;
    }
  }.genre {
    width: 100%;
    padding: 12px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    font-size: 16px;
    color: inherit;
    transition: all 0.3s ease;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
    cursor: pointer;
}

.genre:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.5);
    background-color: rgba(255, 255, 255, 0.2);
}

.genre option {
    background-color: #333;
    color: #fff;
}

/* For Webkit browsers like Chrome/Safari */
.genre::-webkit-scrollbar {
    width: 10px;
}

.genre::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

.genre::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    border: 3px solid rgba(255, 255, 255, 0.1);
}
.search-container {
    position: relative;
    margin-bottom: 20px;
    max-width: 600px; /* Adjust as needed */
    margin-left: auto;
    margin-right: auto;
}

.search-bar {
    display: flex;
    align-items: stretch;
    border: 1px solid #444;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.05);
}

.search-input {
    flex-grow: 1;
    padding: 10px;
    border: none;
    outline: none;
    font-size: 16px;
    background-color: transparent;
    color: inherit;
    width: 70%;
    margin: 0; /* Remove any default margin */
    line-height: 1.5; /* Adjust line height to match button */
    box-sizing: border-box; /* Include padding in element's total width and height */
}

.search-button {
    width: 30%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0; /* Remove any default margin */
    line-height: 1.5; /* Match line height with input */
    box-sizing: border-box; /* Include padding in element's total width and height */
}

.search-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.search-results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: rgba(30, 30, 30, 0.95);
    border: 1px solid #444;
    border-top: none;
    border-radius: 0 0 5px 5px;
    z-index: 1000;
}

.search-result-item {
    padding: 10px;
    cursor: pointer;
    color: #fff;
}

.search-result-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid rgb(4, 125, 141);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .loading-text {
    margin-top: 20px;
    font-size: 18px;
    color: rgb(4, 125, 141);
  }