* {
  box-sizing: border-box;
}

body {
  color: #222
}

img {
  max-width: 100%;
}

main {
  padding: 10px;
  max-width: 100%;
  margin: 0 auto;
}

/* @media screen and (min-width: 1080px) {
  main {
    max-width: 60%;
  }
} */


.layout-container {
  position: relative; /* Ensure positioning context for absolute children */
  /* ... any other styles for layout-container ... */
}

.layout-image {
  position: absolute; /* Position images relative to layout-container */
  width: clamp(200px, 15vw, 300px);
  height: clamp(200px, 15vw, 300px);
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.5;
  pointer-events: none;
  z-index: -2; /* Place behind content */
}

.layout-image.top-left {
  top: 0;
  left: 0;
  background-image: url("/src/images/rangoli.svg"); /* Image for top-left (all pages) */
}

.layout-image.bottom-right {
  bottom: 0;
  right: 0;
  rotate: -180deg;
  background-image: url("/src/images/rangoli.svg"); /* Image for bottom-right (all pages) */
}

.homepage .top-right-home { /* Styles for top-right image ONLY on homepage */
  top: 25%; /* Adjust vertical position as needed */
  right: 0;
  width: clamp(400px, 15vw, 300px);
  height: clamp(600px, 15vw, 300px);
  rotate: -180deg;
  opacity: 0.7;
  background-image: url("/src/images/rang.svg"); /* Image for top-right (homepage) */
}
header {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px;
  align-items: center;
}

header a {
  text-decoration: none;
  color: inherit;
}

header nav {
  display: flex;
  gap: 15px;
}

div.post p.info a.author {
  color: rgb(134, 112, 10)
}

div.post p.summary {
  margin: 10px 0;
}

form.login,
form.register {
  max-width: 400px;
  margin: 0 auto;
}

input {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 7px 7px;
  border: 2px solid #ddd;
  background-color: #fff;
  /* border-bottom: 1px solid #5c2323; */
  border-radius: 5px;
}

button {
  width: 100%;
  display: block;
  background-color: #555;
  border-radius: 5px;
  padding: 7px 0;
  border: 0;
  color: #fff;
}

nav.nav {
  /* display: flex; */
  gap: 15px;
  text-align: center;
  margin: 10px auto;
  font-size: larger;
}

.register-container button {
  max-width: 400px;
  width: 100%;
  text-align: center;
  margin-top: 100px;
  margin: 10px auto;
  color: rgb(0, 0, 0);
  background-color: white;
}

.genre {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  padding: 7px 7px;
  border: 2px solid #ddd;
  background-color: #fff;
  /* border-bottom: 1px solid #5c2323; */
  border-radius: 5px;
}

Typography.post {
  font-family: 'Lucida Sans';
}

/* index.css or App.css */
body {
  --bg-gradient: linear-gradient(0deg, rgb(159, 199, 240) 0%, #fcfcfcfc 10%);
  --text-color: #000000;
  --header-bg: #f0f0f0;
  /* background: var(--bg-gradient); */
  background: #fcfcfcfc;
  color: var(--text-color);
}

body.dark-mode {
  --bg-gradient: linear-gradient(0deg, rgb(6, 58, 82) 0%, rgb(1, 8, 14) 10%);
  background-color: rgb(1, 8, 14);
  --text-color: #ffffff;
  --header-bg: #1a1a1a;
  /* background: var(--bg-gradient); */
  background: rgb(1, 8, 14);
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);

}

/* header {
        background-color: var(--header-bg);
        } */

/* Add more styles as needed */


.highlighted-line {
  height: 4px;
  /* Adjust the height of the line */
  background-color: #46242f;
  /* Change this to your desired highlight color */
  position: absolute;
  bottom: -2px;
  /* Adjust this value to position the line */
  left: 0;
  right: 0;
}

header a.logo {
  font-weight: bolder;
  font-size: 2rem;
  color: var(--text-color);
}

.curved-line {
  position: relative;
  height: 5px;
  /* Height of the line */
  background: linear-gradient(to right, transparent, rgba(185, 20, 20, 0), transparent);
  margin: 10px 0;
  /* Space around the line */
  justify-content: center;
}

.curved-line::before {
  content: '';
  position: absolute;
  /* max-width: 80%; */
  top: -5px;
  /* Adjusts the curve height */
  left: 0;
  right: 0;
  height: 3px;
  /* Height of the curve */
  border-radius: 60%;
  /* Makes it curved */
  background-color: rgba(47, 146, 218, 0.76);
  /* Match with your background color */
}

/* IndexPage.css */

.categories-container {
  padding: 16px;
}

.category {
  margin-bottom: 32px;
}

.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  gap: 16px;
  justify-content: center;
}

.expand-section {
  margin-top: 16px;
  text-align: center;
}

.expand-section button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.expand-section button:hover {
  background-color: #e0e0e0;
}

.expanded-posts {
  margin-top: 16px;
}

@keyframes logoAnimation {
  0% { 
      transform: scale(0) rotate(-180deg); 
      opacity: 0; 
  }
  50% { 
      transform: scale(1.2) rotate(10deg); 
      opacity: 1; 
  }
  100% { 
      transform: scale(1) rotate(0deg); 
      opacity: 1; 
  }
}

@keyframes logoHover {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}


