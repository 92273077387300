/* .create-post-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 30px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
} */

h1 {
    text-align: center;
    color: inherit;
    margin-bottom: 30px;
}

.form-group {
    margin-bottom: 20px;
}

input[type="text"],
textarea,
select,
.custom-file-upload {
    width: 100%;
    padding: 12px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    font-size: 16px;
    color: inherit;
    transition: all 0.3s ease;
}

input[type="text"]:focus,
textarea:focus,
select:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.2);
}

textarea {
    height: 100px;
    resize: vertical;
}

select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 20px;
}

.file-input {
    position: relative;
}

.custom-file-upload {
    display: inline-block;
    cursor: pointer;
    text-align: center;
}

input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.quill-editor {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    overflow: hidden;
}

.quill-editor .ql-toolbar {
    background: rgba(255, 255, 255, 0.2);
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.quill-editor .ql-container {
    border: none;
    height: 300px;
}

.quill-editor .ql-editor {
    font-size: 16px;
    color: inherit;
}

.quill-editor .ql-editor::before {
    color: rgba(255, 255, 255, 0.5);
}

.submit-btn {
    display: block;
    width: 100%;
    padding: 12px;
    background: rgba(255, 255, 255, 0.2);
    color: inherit;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.submit-btn:hover {
    background: rgba(255, 255, 255, 0.3);
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .create-post-container {
        padding: 20px;
    }
}
@media (max-width: 1000px) {
    .create-post-page {
        flex-direction: column;
    }

    .questions-container {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
        max-height: 300px;
    }
}
.create-post-page {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
}

.create-post-container {
    flex: 1;
    max-width: 800px;
    padding: 30px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.questions-container {
    width: 300px;
    margin-left: 20px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: 80vh;
}

.question-item {
    margin-bottom: 15px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
}

.question-username {
    font-weight: bold;
    margin-bottom: 5px;
}